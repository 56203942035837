html {
	overflow-x: hidden;
}

body {
	h1, h2, h3 {
		font-family: Open Sans;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		color: $tc;
		text-transform: uppercase;
	}
	h {
		&1 { font-size: 30px; }
		&2 { font-size: 18px; }
		&3 { font-size: $font-size-base + 2; }
	}
	@media (max-width: $screen-xs-max) {
		h {
			&1 { font-size: 24px; }
			&2 { font-size: 16px; }
			&3 { font-size: $font-size-base; }
		}
		p {
			font-size: $font-size-base;
		}
	}
	p {
		font-size: $font-size-base + 2;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		line-height: normal;
		color: $tc3;
	}
	&.layout-boxed-lg {
		#page {
			max-width: $container-lg + 20px;
			@include box-shadow(0 0 5px lighten($gray, 50%));
			margin: 0 auto;
			background: $white;
		}
	}
	&[class*="page-"] #content {
		padding: 20px 15px;
		img {
			max-width: 100%;
		}
	}
	&.common-home #content {
		padding: 0;
	}
	&[class*="page-category"] {
		#content {
			padding: 0;
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="-account"] #content {
		h1, h2 {
			font-size: $font-size-base + 5;
		}
	}

	&[class*="page-product"] #content {
		overflow: visible;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
		.owl-carousel {
			margin-bottom: 35px;
		}
	}
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

.page-themecontrol-product {
	overflow-y: hidden;
}
/*svg color*/
.st0, .st1 {
	fill: $tc;
	fill-rule:evenodd;
	clip-rule:evenodd;
}
/*
* HEADER 
*/

#topbar {
	background: $topbar-outside-bg;
	font-size: $topbar-font-size;
	color: $topbar-color;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;
	.topbar-left {
		position: relative;
	}
	.custom-top {
		&:nth-child(1) {
			margin-right: 20px;
		}
		i {
			padding-right: 5px;
		}
	}
	.container {
		.inside {
			background: $topbar-bg;
			padding: $topbar-container-padding;
		}
	}

}

.headercart {
	flex: 0 1 330px;
	display: flex;
	align-items: center;
	text-align: right;
	height: 100%;
	justify-content: flex-end;
}

.header-top {
	background: $lg;
	& > .container {
		display: flex;
		height: 50px;
		@media (max-width: 767px) { height: 55px; }
		&:before, &:after {
			content: none;
		}
	}
	#topcontacts {
		display: flex;
		justify-content: flex-start;
		@media (min-width: $screen-md-min) {
			justify-content: space-between;
			width: 100%;
		}
	}
	.topcontacts {
		display: flex;
		align-self: center;
		font-size: 13px;
		@media (min-width: $screen-sm-min) {
			font-size: 15px;
		}
		margin: 5px 0;
		.media-title {
			font-size: 21px;
			margin-right: 10px;
			display: flex;
			align-items: center;
			a {
				display: flex;
			}
			svg {
				@include size(27px, 27px);
				@media (max-width: 340px) {
					@include size(20px, 20px);
				}
			}
		}
		.media-body {
			display: flex;
			span {
				white-space: nowrap;
				font-weight: 600;
				margin-right: 10px;
				@media (max-width: $screen-xs-max) {
					margin-right: 0;
				}
				display: inline-block;
				line-height: 32px;
				a {
					color: $tc !important;
					font-weight: 600;
				}
			}
			&.hw {
				@media (max-width: 767px) {
					line-height: 16px;
				}
				@media (min-width: 768px) {
					display: flex;
					flex-wrap: wrap;
					& > span {
						line-height: 20px;
					}
				}
			}
		}
		&.worktime {
			@media (min-width: 400px) and (max-width: $screen-sm-max) {
				margin-left: 6vw;
			}
		}
		&.top-email {
			@media (max-width: $screen-sm-max) {
				display: none;
			}
			& a:focus {
				text-decoration: none;
			}
		}
	}
	.worktime {
		.media-title svg {
			@include size(23px, 23px);
			@media (max-width: 340px) {
				@include size(17px, 17px);
			}
		}
		.media-body span {
			color: $tc;
		}
	}
	.logo-container {
		flex: 1 0 auto;
		.logo-store {
			text-align: center;
		}
		img {
			max-width: 260px;
		}
	}
	.main-menu {
		flex: 1 0 auto;
		align-self: center;
	}
	.nav-search {
		flex: 0 1 200px;
		align-self: center;
	}
}
.header-middle {
	height: 75px;
	.mobile-header-block { display: none; }
	& > .container {
		height: 100%;
		& > div {
			height: 100%;
		}
		& > div:first-child {
			padding-left: 0;
		}
		& .logo-container {
			height: 100%;
			width: auto;
			display: flex;
			align-items: center;
			#logo {
				width: 100%;
				max-width: 250px;
				a {
					height: 100%;
					width: 100%;
					.svg-logo {
						& > svg {
							width: 85%;
							height: 50px;
						}
					}
					img {
						height: 100%;
						width: auto;
					}
					span {
						bottom: 3px;
						font-size: 9px;
						color: $tc;
						font-weight: 700;
						font-style: italic;
					}
				}
			}
		}
	}
	.close-mobile-search { display: none; }
}
.header-menu {
	background-color: $tc;
	& > .container {
		display: flex;
		justify-content: space-between;
		&:before, &:after {
			content: none;
		}
	}
}
.mobile-logo {
	display: none;
}
.mobile-panel {
	display: none;
}
.shopping-cart-table {
	background: $white;
	tbody {
		.column-image {
			position: relative;
			img {
				border: none;
			}
			.product-label {
				top: 5px;
				left: 5px;
				padding: 0 5px;
				background: $tc;
				span {
					color: $wh;
				}
			}
		}
	}
	& > thead:first-child > tr:first-child > td {
		border-top: 1px solid $lbc;
	}
	&.table {
		tr td {
			border: 1px $lbc solid;
		}
	}
	.btn {
		font-size: 23px;
		padding: 0 7px;
		i {
			vertical-align: 2px;
		}
	}
}

.tp-bullets .counter {
	display: none !important;
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/* multi logo */
.logo-store {
	position: relative;
	@include size(inherit, 80%);
	background-color: $tc2;
	a {
		vertical-align: middle;
	}
}

#logo-theme {
	a {
		background: url(#{$image-theme-path}logo.png) no-repeat;
		display: inline-block;
		@include size(170px, 37px);
		span {
			display: none;
		}
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

/*
* NAVIGATION
*/
#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

#footer {
	ul, ol {
		margin: 0;
		li {
			line-height: 20px;
			a {
				font-size: $font-size-base;
				color: $wh;
				text-transform: uppercase;
				font-weight: 600;
				font-family: $f, bold;
				@include transition(all 0.4s ease 0s);
				&:hover {
					color: $tc2;
					text-decoration: none;
				}
			}
		}
	}
	.footer-name {
		background-color: $tc2;
		.svg-logo {
			height: 50px;
			width: 100%;
			svg {
				width: 85%;
				height: 50px;
			}
		}
		span {
			position: relative;
			bottom: 4px;
			width: 100%;
			font-style: italic;
			font-weight: 600;
			color: $tc;
			@media (min-width: $screen-lg) {
				font-size: 0.7vw;
			}
		}
	}
}

.footer-top {
	background: transparent;
	margin-bottom: -97px;
}

.footer-center {
	background: $tc;
	padding: 30px 0;
	color: $white;
	.container > .row > div:first-child .list-unstyled {
		max-width: 250px;
	}
	.box {
		.box-heading {
			font-size: 18px;
			text-transform: uppercase;
			font-weight: 600;
			margin-bottom: 30px;
			@media (max-width: $screen-xs-max) {
				margin-bottom: 15px;
				margin-top: 25px;
			}
		}
		.list-unstyled:last-child li {
			padding-bottom: 10px;
			&.ft-phone {
				.footer-svg-icon {
					svg {
						@include size(25px, 30px);
					}
				}
				.contats-info-block {
					span {
						width: 100%;
					}
				}
			}
			display: flex;
			.contats-info-block {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding-left: 10px;
				color: $tc3;
				text-transform: uppercase;
				span, a {
					font-weight: 600!important;
					font-size: $font-size-base;
					color: $tc3!important;
					&:hover {
						text-decoration: none;
					}
				}
			}
			.footer-svg-icon {
				display: flex;
				align-items: center;
				svg {
					@include size(25px, 25px);
					& path {
						fill: $tc2;
					}
				}
			}
		}
	}
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $white;
		}
	}
	.footer-name {
		text-align: center;
		font-size: 12px;
	}
	.main-footer {
		display: flex;
		justify-content: space-between;
	}
}

.footer-bottom {
	background: $blue;
	.inside {
		background: url("#{$image-theme-path}pattern.jpg") repeat 0 0;
		position: relative;
		@include rounded-corners(3px);
		@include element-absolute($white);
		.bg-blue {
			background: url("#{$image-theme-path}pattern2.jpg") repeat 0 0;
		}
	}
}

/* POWER BY */
#powered {
	background: $tc;
	a {
		font-size: $font-size-base - 2;
		color: $footer-color;
		font-weight: 500;
		text-transform: uppercase;
		&:hover {
			color: $white;
		}
	}
	.copyright {
		padding: 5px 0 0 15px;
		color: $white;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.inner {
		display: flex;
		align-items: center;
	}
	.oferta p {
		font-size: 12px;
		color: $wh;
	}
	.payment-img {
		img {
			max-height: 50px;
		}
	}
}

/*header adaptive*/
@media (min-width: $screen-md-min) {
	#header-layout {
		.header-middle {
			background-color: $tc2;
		}
	}
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	#header-layout {
		.header-middle {
			& > .container {
				.logo-container #logo a span {
					bottom: 6px;
					font-size: 8px;
				}
				& > div:last-child {
					padding-right: 0;
				}

				& > div:first-child {
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	#header-layout {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		.header-top {
			width: 100%;
			margin-top: 60px;
			#topcontacts {
				width: 100%;
				.topcontacts {
					.media-body {
						flex-wrap: wrap;
						max-width: 150px;
						span {
							line-height: unset;
						}
					}
				}
			}
		}
		.header-middle, .header-menu {
			border-bottom: 1px solid $lg;
			z-index: $zi-mobile-menu;
			background-color: $wh;
			overflow: visible;
		}
		.header-middle {
			position: fixed;
			top: 0;
			left: 15%;
			order: 2;
			width: 85%;
			height: 60px;
			& > .container {
				padding-right: 5px;
				& > div:first-child {
					display: none;
				}
				& > div:nth-child(2) {
					position: relative;
					top: -60px;
					opacity: 0;
					display: flex;
					padding: 0;
					@include transition(all 0.3s ease 0s);
					.nav-search {
						width: 100%;
						.search_block {
							height: 100%;
							#searchtop {
								height: inherit;
								margin-top: 0;
								display: flex;
								align-items: center;
								.form-control {
									right: 0;
								}
								.input-group-btn {
									height: 42px;
									width: 50px;
									left: 0;
									.btn-search {
										height: 100%;
										.svg-icon {
											width: inherit;
											height: inherit;
											display: flex;
											justify-content: center;
											align-items: center;
											svg {
												@include size(25px, 25px);
											}
										}
										&:hover {
											svg path {
												fill: $wh;
											}
										}
									}
								}
							}
						}
					}
				}
				& > div:last-child {
					display: flex;
					position: relative;
					top: -60px;
					opacity: 1;
					flex-wrap: wrap;
					justify-content: flex-end;
					padding: 0;
					@include transition(all 0.3s ease 0s);
					.headercart {
						width: 140px;
						height: inherit;
						flex: none;
						@media (max-width: 400px) {
							width: 115px;
						}
						display: flex;
						justify-content: flex-end;
						align-items: center;
						.product-compare-header, #cart {
							width: 50%;
							height: inherit;
							.cart-inner {
								height: 100%;
								text-align: left;
								a {
									height: inherit;
									width: 100%;
									.wrap-cart {
										height: inherit;
										width: inherit;
										display: flex;
										align-items: center;
										justify-content: space-evenly;
										span {
											position: unset;
										}
									}
								}
								.icon-cart {
									padding: 0;
								}
							}
						}
						.product-compare-header {
							padding: 0;
							a {
								display: flex;
								justify-content: space-evenly;
								align-items: center;
								width: 100%;
							}
						}
					}
					.mobile-header-block {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						height: inherit;
						margin-right: 20px;
						.mobile-search-button {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 34px;
							height: 34px;
							border: 1px solid $tc2;
							border-radius: 30px;
							background-color: $tc2;
							.svg-icon {
								display: flex;
								svg {
									@include size(20px, 20px);
								}
							}
						}
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}
		.header-middle.search-active {
			.container {
				& > div:nth-child(2) {
					top: 0;
					opacity: 1;
					@include transition(all 0.3s ease 0s);
				}
				& > div:last-child {
					top: 0;
					opacity: 0;
					@include transition(all 0.3s ease 0s);
				}
			}
		}
		.header-menu {
			position: fixed;
			top: 0;
			order: 1;
			width: 15%;
			min-width: 60px;
			height: 60px;
			.container {
				height: 100%;
				.main-menu {
					width: 40px;
					#pav-mainnav {
						height: 100%;
						.pav-megamenu {
							height: inherit;
							.canvas-menu {
								width: 100%;
								height: 100%;
								padding: 0;
								.svg-icon {
									display: flex;
								}
								&.cross svg #cm1 {
									transform: translate(-3px, -6px) rotate(45deg);
								}
								&.cross svg #cm2 {
									transform: scaleX(0);
								}
								&.cross svg #cm3 {
									transform: translate(-2px, 5px) rotate(-45deg);
								}
								svg {
									width: 100%;
									height: 35px;
									stroke: $tc;
									stroke-width: 3px;
									path {
										vector-effect: non-scaling-stroke;
										-webkit-transition: all 0.2s ease-in-out;
										-o-transition: all 0.2s ease-in-out;
										transition: all 0.2s ease-in-out;
										transform-origin: left center;
									}
								}
							}
						}
					}
				}
			}
		}
		.mobile-logo {
			width: 100%;
			display: flex;
			justify-content: center;
			padding: 10px 0;
			border-bottom: 1px solid $lg;
			background-color: $tc2;
			.logo-store {
				height: 50px;
				.svg-logo {
					svg {
						width: 85%;
						height: 40px;
					}
				}
				.img-responsive {
					max-width: 140px;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	#header-layout {
		.header-middle {
			height: 150px;
			padding: 15px 0;
			& > .container {
				.logo-container #logo a span {
					bottom: 6px;
					font-size: 9px;
				}
				& > div:last-child, & > div:first-child {
					padding-right: 0;
				}
			}
			.container {
				display: flex;
				flex-wrap: wrap;
				& > div {
					&:first-child {
						order: 1;
						@include size(50%, 70px);
						.logo-container {
							width: 220px;
							#logo {
								@include size(inherit, inherit);
							}
						}
					}
					&:nth-child(2) {
						order: 3;
						width: 100%;
						padding: 0;
						#searchtop {
							.form-control {
								width: calc(100% - 10px);
							}
							.input-group-btn {
								left: 0;
							}
						}
					}
					&:last-child {
						order: 2;
						@include size(50%, 70px);
						.headercart {
							.product-compare-header {
								padding: 0;
								a {
									.svg-icon {
										padding: 0 15px 0 0;
									}
								}
							}
							#cart {
								height: 100%;
								.cart-inner {
									height: inherit;
									a {
										height: inherit;
										.wrap-cart {
											height: inherit;
											span {
												position: unset;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/*footer adaptive*/
@media (max-width: $screen-md-max) {
	#footer {
		.row {
			&::before {
				content: none;
			}
			&::after {
				content: none;
			}
		}
		.inner {
			margin-top: 30px;
		}
		.footer-name {
			width: 225px;
			display: flex;
			flex-wrap: wrap;
			.img-responsive {
				width: 225px;
				height: 100px;
			}
		}
	}
}
@media (max-width: $screen-sm-max) {

}
@media (max-width: $screen-xs-max) {
	#footer {
		.inner {
			margin-top: 0px;
		}
	}
}