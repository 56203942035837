.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 8px;
	vertical-align: middle;
	border-top: $caret-width-base solid;
	border-right: $caret-width-base solid transparent;
	border-left: $caret-width-base solid transparent;
}

// The dropdown wrapper (div)
.dropdown {
	position: relative;
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
	outline: 0;
}

// The dropdown menu (ul)
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: $zindex-dropdown;
	display: none; // none by default, but block on "open" of the menu
	float: left;
	min-width: 400px;
	padding: 10px;
	margin: 2px 0 0; // override default ul
	list-style: none;
	font-size: $font-size-base;
	text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
	background-color: $tc;
	// border: 1px solid $dropdown-fallback-border; // IE8 fallback
	//border: 1px solid $dropdown-border;
	border-radius: $border-radius-base;
	@include box-shadow(0 6px 12px rgba(0, 0, 0, .175));
	background-clip: padding-box;
	color: $text-color;
	width: 90vw!important;
	@media (min-width: $screen-lg-min) {
		width: 1100px!important;
	}
	// Aligns the dropdown menu to right
	//
	// Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
	&.pull-right {
		right: 0;
		left: auto;
	}

	// Dividers (basically an hr) within the dropdown
	.divider {
		@include nav-divider($dropdown-divider-bg);
	}

	// Links within the dropdown menu
	> li > a {
		display: block;
		padding: 3px 20px;
		clear: both;
		font-weight: normal;
		//line-height: $line-height-base;
		color: $text-color;
		white-space: nowrap; // prevent links from randomly breaking onto new lines
	}
}

// Hover/Focus state
.dropdown-menu > li > a {
	&:hover,
	&:focus {
		text-decoration: none;
		// color: $dropdown-link-hover-color;
		//background-color: $dropdown-link-hover-bg;
	}
}

// Active state
.dropdown-menu > .active > a {
	&,
	&:hover,
	&:focus {
		color: $tc2;
		text-decoration: none;
		outline: 0;
	}
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu > .disabled > a {
	&,
	&:hover,
	&:focus {
		color: $dropdown-link-disabled-color;
	}

	// Nuke hover/focus effects
	&:hover,
	&:focus {
		text-decoration: none;
		background-color: transparent;
		background-image: none; // Remove CSS gradient
		@include reset-filter;
		cursor: $cursor-disabled;
	}
}

// Open state for the dropdown
.open {
	// Show the menu
	> .dropdown-menu {
		display: block;
	}

	// Remove the outline when :focus is triggered
	> a {
		outline: 0;
	}
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
	left: auto; // Reset the default from `.dropdown-menu`
	right: 0;
}

// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
	left: 0;
	right: auto;
}

// Dropdown section headers
.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: $font-size-small;
	line-height: $line-height-base;
	color: $dropdown-header-color;
	white-space: nowrap; // as with > li > a
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: ($zindex-dropdown - 10);
}

// Right aligned dropdowns
.pull-right > .dropdown-menu {
	right: 0;
	left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?

.dropup,
.navbar-fixed-bottom .dropdown {
	// Reverse the caret
	.caret {
		border-top: 0;
		border-bottom: $caret-width-base solid;
		content: "";
	}
	// Different positioning for bottom up menu
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-bottom: 1px;
	}
}

/*megamenu*/
@media (min-width: $screen-sm-min) {
	.dropdown-menu {
		height: 425px;
		background-color: $wh;
		padding: 0;
		&.active {
			li.haschildren.first-subcat {
				background-color: $wh;
				& > a {
					color: $tc2!important;
				}
				& > .children {
					opacity: 1!important;
					visibility: visible!important;
				}
			}
		}
		.dropdown-menu-inner {
			height: 100%;
			.row {
				@include size(100%, 100%);
				margin: 0;
				& > .mega-col {
					height: inherit;
					padding: 0;
					& > .mega-col-inner {
						height: inherit;
						& .pavo-widget {
							height: inherit;
							& > .panel {
								height: inherit;
								& > .tree-menu {
									height: inherit;
									ul.list-arrow {
										height: inherit;
										width: 25vw;
										@media (min-width: $screen-lg-min) {
											width: 250px;
										}
										padding: 25px 0 25px 30px;
										background-color: $lg;
										li.haschildren {
											padding: 15px 10px;
											position: static;
											transition-delay: .1s;
											& > a {
												text-transform: uppercase;
												display: block;
												font-size: 16px;
												font-family: $hf;
												transition-delay: .1s;
											}
											ul.children {
												display: flex;
												flex-wrap: wrap;
												align-items: center;
												opacity: 0;
												visibility: hidden;
												transition: all .3s ease .15s;
												position: absolute;
												top: 0;
												left: 25vw;
												width: 65vw;
												@media (min-width: $screen-lg-min) {
													width: 850px;
													left: 250px;
												}
												padding: 35px 30px 0 30px;
												background-color: $wh;
												z-index: $zi-header-menu;
												& > li {
													width: 33%;
													a {
														color: $tc;
														text-transform: uppercase;
														font-size: 14px;
														font-family: $hf;
													}
													&:hover {
														background-color: $wh;
														a {
															color: $tc2;
														}
													}
												}
											}
											&:hover {
												& > .children {
													opacity: 1;
													visibility: visible;
												}
												background-color: $wh;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	.dropdown-menu {
		.dropdown-menu-inner {
			.row {
				.mega-col {
					& > .mega-col-inner {
						& .pavo-widget {
							& > .panel {
								& > .tree-menu {
									ul.list-arrow {
										li.haschildren {
											padding: 10px;
											a {
												font-size: 14px;
											}
											ul.children {
												top: 0;
												left: 25vw;
												padding: 30px 30px 0 30px;
												& > li {
													a {
														font-size: 14px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	.dropdown-menu {
		.cat_all > li .click-allcat {
			display: none;
		}
		.dropdown-menu-inner {
			.row {
				.mega-col {
					& > .mega-col-inner {
						& .pavo-widget {
							& > .panel {
								& > .tree-menu {
									ul.list-arrow {
										li.haschildren {
											padding: 10px;
											a {
												font-size: 14px;
												padding: 0;
												width: 100%;
												color: $tc;
												span {
													width: 100%;
												}
											}
											&:hover {
												a {
													color: $tc2;
												}
											}
											ul.children {
												top: 0;
												left: 25vw;
												padding: 30px 30px 0 30px;
												& > li {
													padding: 5px;
													a {
														font-size: 14px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
// Component alignment
//
// Reiterate per navbar.less and the modified component alignment there.

@media (min-width: $grid-float-breakpoint) {
	.navbar-right {
		.dropdown-menu {
			right: 0;
			left: auto;
		}
		// Necessary for overrides of the default right aligned menu.
		// Will remove come v4 in all likelihood.
		.dropdown-menu-left {
			left: 0;
			right: auto;
		}
	}
}
