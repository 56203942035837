body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

/* search */
#searchtop {
	margin-top: 15px;
	input.form-control {
		background: $wh;
		border-radius: 0;
		border: 1px solid $lg;
		color: $tc;
		height: 42px;
		@include placeholder($bc);
	}
	.input-group-btn {
		background: $lg;
		color: $tc;
		text-align: center;
		@include transition(all 0.2s ease-in-out);
		left: 10px;
		&:hover {
			.btn-search {
				color: $wh;
			}
			background: $tc;
		}
	}
	.btn-search {
		display: inline-block;
		background: transparent;
		font-size: 14px !important;
		font-weight: 600;
		font-family: $f;
		cursor: pointer;
		color: $tc;
		@include transition(all 0.2s ease-in-out);
		.svg-icon { display: none; }

	}
}
@media (max-width: 767px) {
	.btn-search span { display: none; }
	.btn-search i { display: block; }
}
.form-control::-moz-placeholder {
	color: $bc;
}

// module cart
#cart {
	width: auto;
	.cart-inner {
		height: 30px;
	}
	&.open .dropdown-toggle {
		box-shadow: none;
	}
	a {
		display: inline-block;
		position: relative;
		text-decoration: none;
		&:hover {
			#cart-total {
				color: $tc;
			}
			.icon-cart svg path {
				@media (min-width: $screen-md-min) {
					fill: $wh;
				}
			}
		}
	}
	.icon-cart {
		background: transparent;
		display: flex;
		align-items: center;
		position: relative;
		text-align: center;
		padding: 0 15px 0 0;
		height: 31px;
		i {
			font-size: 36px;
			line-height: 28px;
		}
		svg {
			@include size(23px, 23px)
		}

	}
	.wrap-cart {
		display: flex;
		min-width: 50%;
		&:last-child {
			width: max-content;
			display: flex;
			align-items: center;
		}
		span {
			position: relative;
		}
		#cart-total {
			font-family: $f;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			font-size: 14px;
			text-transform: uppercase;
			color: $tc3;
			@media (min-width: $screen-md-min) {
				color: $axr;
			}
		}
		.badge {
			line-height: 20px;
			z-index: 99;
			@include colors($tc, $tc2);
			font-weight: 600;
		}
	}
	.price-cart {
		color: $tc;
	}
}

.product-compare-header {
	display: flex;
	width: auto;
	a {
		display: flex;
		&:hover {
			text-decoration: none;
			#compare-total {
				color: $tc;
			}
			svg path {
				@media (min-width: $screen-md-min) {
					fill: $wh;
				}
			}
		}
		div {
			display: flex;
			align-items: center;
			svg {
				@include size(28px, 28px)
			}
		}
	}
	p {
		margin: 0;
		&#compare-total {
			font-family: $f;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			font-size: 14px;
			text-transform: uppercase;
			color: $tc3;
			@media (min-width: $screen-md-min) {
				color: $axr;
			}
			padding-left: 10px;
		}
	}
	i {
		font-size: 32px;
		line-height: 31px;
		display: block;
	}
	.badge {
		color: $wh;
		min-width: 17px;
		line-height: 20px;
		z-index: 99;
		background: $r;
		font-weight: 600;
	}
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

/* pagination */
.pagination {
	li {
		span, a {
			@include border-radius($border-color, 3px)
		}
	}
}

/* product page */
.page-product {
	#tab-specification {
		& > .table.table-bordered > thead {
			display: none;
		}
		& > .table.table-bordered > tbody:not(:last-child) tr td:first-child {
			color: $tc3;
		}
	}
	strong {
		color: $tc;
	}
	li {
		font-size: 16px;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		line-height: normal;
		color: $tc3;
	}
	.product-view {
		display: flex;
		flex-wrap: wrap;
		padding: 0;

		.title-product {
			width: 100%;
			text-align: left;
		}
		.list-unstyled {
			li {
				font-size: 16px;
				color: $dg;
				.text-primary {
					display: none;
				}
			}
		}
		.prod_rating {
			width: 100%;
			.stars {
				color: $tc2;
			}
			#scr_revs, #scr_newrev {
				font-size: 16px;
				color: $dg;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.price {
			width: 100%;
			margin-bottom: 10px;
		}
	}
	#review {
		.review-item {
			.review-header {
				display: flex;
				background-color: $lg;
				.review-left-header {
					width: 80%;
					display: flex;
					flex-wrap: wrap;
					font-size: 16px;
					font-weight: 400;
					font-family: "Rubik", sans-serif;
					text-transform: uppercase;
					& > div {
						margin: 10px;
						&.rating {
							color: $tc2;
						}
					}
				}
				.review-right-header {
					width: 20%;
					display: flex;
					justify-content: flex-end;
					margin: 10px;
				}
			}
			.review-main {
				.review-title {
					margin: 20px 10px 5px 10px;
					font-size: 16px;
					font-weight: 400;
					font-family: "Rubik", sans-serif;
					text-transform: uppercase;
					color: $tc;
				}
				.review-text {
					margin: 0 10px;
					&:last-child {
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}