
.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.price {
	font-weight: 400;
	font-family: $hf;
	.price-new {
		color: $tc;
		font-size: 20px;
	}
	.price-old {
		text-decoration: line-through;
		color: $wh;
		font-size: 15px;
	}
	.price-new:not(:first-child) {
		color: $r;
	}
	&.detail {
		.price-new {
			font-size: 40px;
			@media (max-width: 375px) {
				font-size: 30px;
			}
		}
		.price-old {
			font-size: 26px;
			color: $tc;
			margin-right: 10px;
		}
		ul {
			margin: 0;
		}
	}
}

.cheap_invoice {
	display: flex;
	flex-wrap: wrap;
	margin: 15px 0;
	a {
		font-size: 18px;
		font-weight: 600;
		display: block;
		color: $tc;
		margin-right: 20px;
		text-decoration: underline;
		&:hover {
			color: $tc2;
		}
	}
}

.product-block {
	position: relative;
	background-color: $white;
	border-top: 1px solid transparent;
	@include rounded-corners(3px);
	.description {
		display: none;
	}
	.product-meta {
		line-height: 25px;
		padding: 0;
		.top {
			.rating {
				.wrap {
					width: fit-content;
					margin: 0 auto;
					color: $tc2;
				}
			}
		}
	}
	.bottom {
		display: block;
	}
	.name {
		margin: 10px 0;
		font-size: $font-size-base + 2;
		font-family: $font-family-base;
		text-transform: inherit;
		overflow: hidden;
		font-weight: 600;
		a {
			padding: 5px 0;
			color: $tc;
			span {
				text-align: center;
				display: block;
				color: $tc;
				font-family: $hf;
				&.title1 {
					font-weight: 400;
				}
				&.title2 {
					font-weight: 500;
					font-size: 18px;
					line-height: 26px;
				}
			}
			&:hover {
				text-decoration: none;
				span {
					color: $tc2;
				}
			}
		}
	}
	.compare, .quickview {
		position: absolute;
		@include size(45px, 45px);
		.svg-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			svg {
				@include size(22px, 22px);
			}
		}
		a, button {
			width: 100%;
			height: 100%;
			background-color: $lg;
			border: none;
		}
	}
	.image {
		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
		padding: 0;
	}
	.top {
		position: relative;
		padding: 0 20px 10px 20px;
	}
}

#product {
	.quantity {
		.btn-primary, .invoice {
			height: 40px;
			line-height: 40px;
		}
		.btn {
			margin-right: 10px;
		}
		#button-cart {
			display: flex;
			width: 214px;
			@media (min-width: 451px) {
				justify-content: center;
			}
			margin: 0;
			@include transition(all 0.2s ease 0s);

			.svg-icon {
				display: flex;
				align-items: center;
				height: inherit;
				margin-right: 7px;
				svg {
					@include size(30px, 30px);
					path {
						fill: $tc;
						@include transition(all 0.2s ease 0s);
					}
				}
			}
			&:hover, &:active, &:focus {
				svg path {
					fill: $tc2;
				}
			}
		}
	}
}

@media (max-width: 450px) {
	#product {
		#button-cart {
			width: calc(100% - 115px);
			justify-content: center;
			.svg-icon {
				margin-right: 15px;
			}
		}
	}
}

.product-btn-group-second {
	.btn-primary {
		background-color: $lg;
		border-color: $lg;
		margin-left: 0 !important;
		height: 36px !important;
		.svg-icon {
			height: 100%;
			display: flex;
			align-items: center;
			svg {
				@include size(30px, 30px);
			}
		}
		&:hover {
			background-color: $tc2;
			border-color: $tc2;
		}
	}
	.invoice {
		margin-left: 6px;
		font-size: 11px;
		padding: 0 7px;
	}
}

.cart {
	position: relative;

	.btn-primary {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		@include transition(all 0.2s ease 0s);
		transition-delay: 0s;
		.svg-icon {
			display: flex;
			align-items: center;
			svg {
				@include size(30px, 30px);
				path {
					fill: $tc;
					@include transition(all 0.2s ease 0s);
				}
			}
		}
		&:hover, &:active, &:focus {
			transition-delay: 0s;
			.svg-icon svg path {
				fill: $tc2;
			}
		}
	}
	.icon-cart {
		display: block;
		@include size(16px, 17px);
	}
}

.product-label {
	display: block;
	width: max-content;
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
	text-align: left;
	line-height: 14px;
	padding: 5px 10px;
	margin-right: 5px;
	margin-bottom: 5px;
	color: $wh;

	&.sale, &.markdown-product {
		background-color: $label-sale;
	}
	&.new {
		background-color: $label-new;
	}
	&.present-inside {
		background-color: $label-present;
	}
	&.presale-prepare {
		background-color: $deepBlu;
	}
}
.stickers {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	position: absolute;
	top: 0;
	left: 0;
}

.product-list {
	.product-block {
		.product-label {
			top: 7px;
			&.sale {
				left: 10px;
			}
			&.new {
				right: 9px;
			}
		}
	}
}

.product-info .tab-content {
	border: none;
	padding: 0;
	margin-bottom: 30px;
	.for-send-error-press p {
		font-style: italic;
		margin: 20px 0;
	}
	.desc-header-wrapper {
		border: 1px $lg solid;
		margin-top: 30px;
		margin-bottom: 15px;
		.desc-header {
			padding: 15px;
			color: $tc;
			text-transform: uppercase;
			font-size: 18px;
			position: relative;
			top: 1px;
			display: inline-block;
		}
	}
	#tab_review ~ .desc-header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	#tab-gifts {
		.product-gifts {
			.item {
				display: flex;
				align-items: center;
				flex-wrap: nowrap;

				.image {
					margin-right: 10px;
					border: 0;

					img {
						width: 160px;
					}
				}
				.title {
					a {
						font-size: 18px;
					}
				}
			}
		}
	}
	#tab-description {
		padding: 0 15px;
	}
	#tab-specification {
		td {
			padding: 10px 15px !important;
			color: $tc;
		}
	}
	.owl-related {
		.owl-next, .owl-prev {
			top: 40%;
			font-size: 33px;
			@include size(35px, 35px);
			@media (max-width: 767px) {
				display: none;
			}
		}
		.owl-next {
			right: -15px;
			.owl-arrow-left {
				display: none;
			}
		}
		.owl-prev {
			left: -15px;
			.owl-arrow-left {
				display: none;
			}
		}
		.owl-stage {
			padding: 0;
		}
		.product-block {
			width: 100%;
			margin: 0;
			.image {
				border: none;
			}
		}
	}
	.widget-products {
		margin: 0 -2px;
	}
	.scroll-button {
		margin-right: 15px;
		background-color: $tc2;
		border-color: $tc2;
		color: $tc;
		&:hover {
			background-color: $tc;
			border-color: $tc;
			color: $tc2;
		}
	}
	.product-grid.no-scroll {
		.product-block {
			margin: 4px 2px;
			width: calc(100% / 4 - 7px);
			.image {
				border: 0;
			}
		}
	}
}

.product-grid {
	.product-block {
		.name {
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.storage_widget {
	border-top-color: $tc3;
	color: $tc3;
	& > span {
		color: $tc !important;
		@include transition(all .2s ease-in-out);
	}
	table tr {
		td:first-child span {
			background: #ffd71a !important;
			background-image: none !important;
			border: solid $tc 1px !important;
			border-radius: 50% !important;
		}
		td:last-child span {
			background: $r !important;
			color: $wh !important;
			border-radius: 0 !important;
			font-weight: 600;
			text-transform: uppercase;
			font-size: 14px !important;
			padding: 7px 12px !important;
			font-family: "Open Sans", sans-serif !important;
			&:hover, &:active, &:focus {
				background: $tc !important;
			}
		}
	}
	& > div {
		font-size: 11px !important;
	}
}