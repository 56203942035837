@media (min-width: 992px) {
	i.click-allcat {
		display: none;
	}
	#cart {
		margin-left: 15px;
	}
}
@media (max-width: $screen-md-max) {
	.mfilter-content {
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li > a {
		padding: 14px 10px;
		font-size: 14px;
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.mfilter-free-container {
		top: 174px;
	}
	.header-bottom {
		.nav-search {
			margin-bottom: 7px;
		}
	}
	.refine-search ul li a span, .refine-search ol li a span {
		line-height: 15px;
		font-size: 12px;
		overflow: hidden;
	}
}
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	.pav-megamenu .navbar-nav > li > a {
		padding: 14px 7px;
	}
}
@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
}
/*$media (max-width: 1199px) {*/
@media (max-width: $screen-md-max) {
	.product-grid {
		.product-block {
			.product-grid .product-block {
				width: calc(100% / 3 - 20px);
				margin: 5px;
			}
			width: calc(100% / 3 - 20px);
		}
	}
}
/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.widget-images {
		margin-bottom: 30px;
	}
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			.product-grid .product-block {
				width: calc(100% / 2 - 20px);
				margin: 5px;
			}
			width: calc(100% / 2 - 20px);
		}
	}
	.header-bottom {
		& > .container {
			height: 100px;
		}
		.logo-container {
			text-align: center;
			flex: 0 0 200px;
			order: 1;
			img {
				max-width: 100%;
			}
		}
		#topcontacts {
			order: 2;
		}
		.headercart {
			display: flex;
			order: 3;
			flex: 0 1 150px;
			min-width: 150px;
		}
	}
	#powered {
		.copyright {
			order: 2;
		}
		.payment {
			order: 1;
			text-align: center;
			.payment-img {
				float: none !important;

				img {
					max-height: 60px;

					&:first-child {
						display: none;
					}
				}
			}
		}
	}
	.payment2row {
		display: block;
	}
	body .mfilter-free-container {
		top: 244px !important;
		padding: 0 !important;
		margin-right: -222px;
		margin-left: auto;
		right: 0;
		left: auto;
		border-color: $bc;
		&.mfilter-direction-rtl {
			margin-right: -222px;
		}
		#mfilter-free-container-0 {
			padding: 5px 10px !important;
			overflow: auto !important;
		}
		.mfilter-box {
			margin-bottom: 5px;
		}
		.mfilter-free-button {
			background: $theme-color;
			margin: 0 220px 0 -45px;
			border: 1px solid $bc;
			padding: 9px 5px;
			top: -1px;
			width: 33px;
			height: 23px;
			&:before {
				content: '\f1f3';
				font-family: $fa;
				color: $white;
				font-size: 28px;
				line-height: 23px;
				padding: 3px;
				left: -1px;
				position: relative;
			}
		}
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 65px;
			min-width: 65px;
			padding: 9px 7px;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li{
		.fa, a {
			color: $wh;
		}
		&:hover {
			a, .fa {
				color: $tc2;
			}
		}
	}
	.breadcrumbs {
		padding: 15px 0;
	}
	.h1_container {
		h1 {
			padding: 18px 15px 21px 0;
			font-size: 26px;
		}
	}
	.product-compare-header {
		position: relative;
		padding: 13px 10px 0 14px;
		a {
			&:hover {
				text-decoration: none;
			}
		}
	}
	#cart {
		.icon-cart {
			padding: 0 17px;
			height: 50px;
			i {
				line-height: initial;
				vertical-align: -25px;
			}
		}
	}
	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 1px 15px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $tc;
			}
			&.haschildren {

				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 40px);
					display: inline-block;
				}
				&.opened ul {
					display: block;
				}
			}
		}
	}
	.category_widget {
		.type1 {
			height: 300px;
			h2 {
				a {
					font-size: 15px;
					padding: 27px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(100% / 3 - 20px);
	}
	.main-menu {
		.canvas-menu {
			color: $wh;
			padding: 0 15px;
		}
	}
	.tree-menu ul ul li {
		padding: 2px 0;
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 3 - 7px);
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
}

@media (max-width: $screen-xs-max) {
	.product-grid {
		.product-block {
			width: calc(100% / 2 - 20px);
		}
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	.producttabs {
		margin-top: 15px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.tab-v3 {
		.nav-tabs {
			flex-wrap: wrap;
			border: none;
			& > li {
				width: 100%;
				text-align: center;
				border: none;
				&:last-child {
					border: none;
				}
				&.active a {
					border: none;
				}
				& > a {
					border: none;
					padding: 10px 15px;
					font-size: 18px;
				}
			}
		}
	}
	.h1_container {
		h1 {
			width: 100%;
			font-size: 20px;
		}
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, & tr {
			display: block;
			border: 0;
			width: 100%;
		}
		td {
			position: relative;
			padding-left: 43% !important;
			text-align: left !important;
			white-space: normal !important;
			&:first-child {
				border-top: 0;
				padding: 0 !important;
				text-align: center !important;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				color: $tc;
				@include transform(translateY(-50%));
				font-weight: bold;
				text-transform: uppercase;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}
	.header-bottom {
		#topcontacts {
			justify-content: center;
			min-width: 133px;
			.worktime {
				display: none;
			}
		}
	}
	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	.header-bottom {
		.main-menu {
			flex: 0 0 auto;
		}
		.nav-search {

		}
	}
	#powered .inner {
		flex-wrap: wrap;
		.copyright {
			order: 2;
			margin-bottom: 15px;
		}
		.payment {
			order: 1;
			.payment-img {
				max-width: 100%;
			}
		}
	}
	#input-enquiry {
		height: 100px;
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
	}
	.breadcrumb > li {
		display: inline;
		font-size: 13px;
	}
	.product-markdown-view {
		#product {
			width: 100%;

			.cheaper-products {
				.cheaper-item {
					#button-cart {
						max-width: 155px;
					}
					&.markdown {
						#button-cart {
							max-width: 270px;
						}
					}
				}

			}
		}
	}
}

@media (max-width: 637px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 30px 10px 15px 10px;
		.success_button {
			padding: 6px;
			font-size: 12px;
		}
		& > div {
			height: 30px;
		}
		& > div:first-child {
			margin-top: 20px;
		}
		br {
			display: none;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 2 - 7px);
	}
}

@media (max-width: 570px) {
	.fancy_holiday_works {
		width: 280px !important;
		.fancybox-skin {
			padding: 30px 10px !important;
			background-color: #fff;
			.fancybox-inner {
				width: 260px !important;
			}
			.holiday_table_wrap {
				font-size: 14px;
			}
			.holiday_table {
				td {
					font-size: 14px;
				}
			}
		}
	}
	.holiday_works {
		margin-top: 0;
	}
	#powered > .container {
		padding: 0;
	}
	.breadcrumbs {
		padding: 15px;
		.container {
			padding: 0;
		}
	}
	.header-bottom {
		& > .container {
			height: auto;
			position: relative;
		}
		.logo-container {
			flex: 0 0 150px;
			margin: 10px 0;
		}
		#topcontacts {
			position: absolute;
			right: 160px;
			flex: 0 1 auto;
			min-width: unset;
		}
		#topcontacts, .headercart {
			flex: 0 1 auto;
		}
		.headercart {
			min-width: 133px;
		}
		.topcontacts {
			justify-content: center;
			max-width: 35px;
			min-width: auto;
			.media-title {
				padding: 0;
			}
			.media-body {
				display: none;
			}
			em {
				font-size: 30px;
			}
		}
	}
	#searchtop {
		border: 0;
		.input-group-btn {
			height: 30px;
			&:hover {
				background: $tc;
			}
		}
		.btn-search {
			line-height: 14px;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.product-info .tab-content {
		.desc-header-wrapper .desc-header {
			display: block;
			padding: 10px;
			font-size: 15px;
		}
	}
}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% - 5px);
	}
	.header-bottom {
		.logo-container {
			flex: 0 0 130px;
			padding-right: 10px;
		}
		.headercart {
			min-width: 120px;
		}
		#topcontacts {
			right: 133px;
		}
	}
	.product-compare-header {
		padding-left: 0;width: 40px;
	}
	#cart {
		.icon-cart {
			padding: 0 10px;
		}
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(100% - 20px);
	}
	.product-markdown-view {
		.cheaper-products {
			.cheaper-items {
				.cheaper-item {
					&__price {
						.price-new {
							font-size: 24px;
						}
						.price-old {
							font-size: 18px;
						}
					}
				}
			}
			&.general {
				.cheaper-items {
					.cheaper-item {
						&__price {
							.price-new {
								font-size: 30px;
							}
							.price-old {
								font-size: 20px;
							}
						}
					}
				}
			}
		}
		.product-btn-group-second {
			gap: 5px;
		}
	}
}

@media (max-width: 560px) {
	.product-grid {
		.product-block {
			width: 100%;
		}
	}
}
/*------------------------------------
				payment
 -----------------------------------*/

/* All */
@media (min-width: $screen-lg-min) {
	#payment {
		p, span {
			font-size: 16px;
		}
	}
}
#payment {
	p, span {
		font-size: 14px;
	}
}
.well {
	padding: 0px !important;
	color: $tc;
	font-size: 16px;
}
#payment {
	h2 {
		text-transform: none;
	}
	li {
		color: $tc3;
	}
}
#content {
	.well > h1 {
		margin-top: 50px;
		margin-bottom: 0px;
		padding-bottom: 10px;
		position: relative;
		display: block;
		padding-left: 30px;
	}
	.well > h1:after {
		width: 30px;
		height: 2px;
		background: #e85529;
		content: '';
		position: absolute;
		bottom: 0;
		left: 34px;
	}
}

@media (max-width: 767px) {
	#payment .payment-name {
		margin-top: 20px;
	}
	.page-product {
		.product-view {
			padding: 0 16px;
		}
	}
	.product-info {
		.tab-content {
			#tab-gifts {
				.product-gifts {
					.item {
						.image {
							flex: 0 0 33%;
						}
						.title {
							a {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
		.product-meta {
			flex-direction: column;
		}
	}
	#product {
		.quantity {
			#button-cart {
				width: 100%;
			}
		}
	}
	.product-btn-group-second {
		.btn-primary {
			display: none;
		}
	}
	#button-cart-credit {
		margin-left: 0;
	}
}

/* Cash */
#payment {
	.cash_left {
		padding: 0;
		padding-left: 30px;
	}
	.cash_right {
		padding: 0;
	}
	.cash {
		margin: 0;
		padding: 0px;
		padding-right: 0px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	#payment .cash_left {
		padding: 0px 30px;
	}
}

/* No Cash */
#payment {
	.no-cash_left {
		padding: 0;
		padding-left: 30px;
		padding-bottom: 40px;
	}
	.no-cash_right {
		padding: 0;
	}
	.no-cash {
		margin: 0;
		padding: 0px;
		border-top: none;
		border-bottom: none;
		padding-right: 0px;
		margin-top: 70px;
	}
	.gr {
		display: block !important;
	}
	.payment_grid {
		display: -webkit-flex;
		-webkit-flex-wrap: wrap;
		display: flex;
		height: 100%;
		overflow: hidden;
		p {
			font-size: 13px!important;
		}
	}
	.no-cash_right .payment_grid .gr {
		width: 20%;
		padding: 10px;
		border-left: none;
		position: relative;
		background: #fff;
		top: 0;
	}
	.no-cash_right .payment_grid .gr .gr-inner {
		border: 1px solid $lbc;
		padding: 10px;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		height: 100%;
		width: 100%;
		align-content: baseline;
	}
	&.apple .no-cash_right .payment_grid .gr {
		width: calc(100% - 1px);
	}
}
#feature1 {
	z-index: 50;
	left: -20%;
}

#feature2 {
	z-index: 40;
	left: -40%;
}

#feature3 {
	z-index: 30;
	left: -60%;
}

#feature4 {
	z-index: 20;
	left: -80%;
}

#feature5 {
	z-index: 20;
	left: -100%;
}
#payment {
	.payment_grid {
		.gr:first-child, .gr:last-child {
			border-left: none !important;
		}
		.gr:last-child {
			border-right: none !important;
		}
		.gr:last-child {
			border-right: none !important;
		}
		.gr img {
			margin: 0 0 10px 0;
			max-width: 65px;
			align-self: center;
		}
		.gr p {
			margin: 0;
			clear: both;
			line-height: 20px;
			text-align: center;
			width: 100%;
		}
	}
}
@media (max-width: 991px) {
	#payment {
		.cash_right img {
			margin-top: 30px;
		}
		.no-cash_left {
			padding: 0px 30px;
			padding-bottom: 40px;
		}
		.no-cash_right .payment_grid .gr {
			width: 100%;
			display: flex !important;
			align-items: center;
			border-right: none;
			border-left: none;
		}
		&.apple .payment_grid .gr {
			width: calc(100% - 1px);
		}
		.payment_grid {
			.gr:nth-child(-n+2) {
				border-bottom: none;
			}
			.gr:nth-child(3), .gr:nth-child(4) {
				border-bottom: none;
			}
			.gr p {
				display: inline;
			}
			.gr img {
				flex-shrink: 0;
			}
		}
	}
	#feature1, #feature2, #feature3, #feature4 {
		left: -100%;
	}
}

@media (max-width: 767px) {
	#payment {
		.cash_left, .no-cash_left {
			padding: 0;
		}
		.no-cash {
			margin-top: 20px;
		}
		.payment_grid .gr {
			width: 100%;
		}
		.no-cash_left {
			padding-bottom: 20px;
		}
	}
}

@media (max-width: 600px) {
	#payment .no-cash_right .payment_grid .gr {
		width: 100%;
	}
	#feature1, #feature2, #feature3, #feature4 {
		left: -100%;
	}
}

@media (max-width: 500px) {
	.payment_grid .gr {
		width: 100%;
	}
}

/* Bank card */
#payment {
	.bank-card_left {
		padding: 0;
		padding-left: 30px;
	}
	.bank-card_right {
		padding: 0;
		padding-right: 70px;
		padding-left: 70px;
	}
	.bank-card {
		margin: 0;
		padding: 0px;
		border-bottom: 1px solid #ededed;
		padding-right: 0px;
		padding-bottom: 50px;
		margin-top: 20px;
	}
	.bank-card_right__ul li, .webmoney_left__ul li {
		list-style-type: none;
		margin: 1px 0;
		padding: 0 0 14px 0;
	}
	.bank-card_right__ul li:last-child, .webmoney_left__ul li:last-child {
		border-left: 2px solid #fff;
	}
	.bank-card_right__ul {
		padding-left: 0;
		margin-top: 20px;
		margin-bottom: 65px;
	}
}
@media (max-width: 991px) {
	#payment .bank-card_left {
		padding: 0px 30px;
	}
}

@media (max-width: 767px) {
	#payment {
		.bank-card_left, .bank-card_right {
			padding: 0;
		}
		.bank-card {
			padding-bottom: 20px;
		}
		.bank-card_right__ul {
			margin-bottom: 20px;
		}
	}
}

/* Terminals */
#payment {
	.terminals {
		#lines {
			position: absolute;
			height: 100%;
			width: 100%;
			opacity: 0.4;
			#polyline {
				stroke: $tc;
				fill: none;
			}
			@media (max-width: $screen-sm-max) {
				display: none;
			}
		}
		.oplata_points {
			@media (max-width: $screen-sm-max) {
				display: none;
			}
		}
		.terminals-1_right .terminals-1_right__image img, .terminals-2_left .terminals-2_left__image img, .terminals-3_right .terminals-3_right__image img, .terminals-5_right .terminals-5_right__image img {
			display: inline-block;
		}
		.terminals-3_right::before {
			content: '';
			position: absolute;
			right: 8px;
			top: -55px;
			border: 245px solid transparent;
			border-right: 400px solid $tc;
			z-index: 1;
			@media (min-width: 1400px) {
				border: 30vh solid transparent;
				border-right: 31vw solid $tc;
			}
		}
		.terminals-1_left, .terminals-3_left, .terminals-4_right, .terminals-5_left {
			padding: 0;
			padding-left: 30px;
		}
		.terminals-1_right, .terminals-2_left, .terminals-3_right, .terminals-4_left, .terminals-5_right {
			padding: 0;
		}
		.terminals-1_right .terminals-1_right__image {
			text-align: right;
		}
		.terminals-2 {
			padding-top: 60px;
		}
		.terminals-2_left .terminals-2_left__image, .terminals-3_right .terminals-3_right__image, .terminals-5_right .terminals-5_right__image {
			text-align: center;
		}
		.terminals-2_right {
			padding: 0;
			padding-right: 70px;
			padding-top: 115px;
		}
		.terminals-3_left {
			padding-right: 40px;
			padding-top: 170px;
		}
		.terminals-4_left {
			padding-left: 35px;
		}
		.terminals-4_right {
			padding-right: 40px;
			padding-top: 175px;
		}
		.terminals-5 {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		.terminals-5_left {
			padding-right: 140px;
			padding-top: 210px;
		}
	}
	.terminals {
		.terminals-1_left__description {
			padding-left: 60px;
			padding-top: 50px;
		}
		.terminals-2_right__header {
			padding-left: 45px;
		}
		.terminals-2_right__description {
			padding-left: 45px;
			padding-top: 10px;
		}
		.terminals-3_left__header {
			padding-left: 65px;
		}
		.terminals-3_left__description {
			padding-left: 65px;
			padding-top: 10px;
		}
		.terminals-4_right__header {
			padding-left: 115px;
		}
		.terminals-4_right__description {
			padding-left: 115px;
			padding-top: 10px;
		}
		.terminals-5_left__header {
			padding-left: 65px;
		}
		.terminals-5_left__description {
			padding-left: 65px;
			padding-top: 10px;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	#payment {
		.terminals {
			.terminals-1_left__description {
				padding-left: 50px;
				padding-top: 10px;
			}
			.terminals-2_right {
				padding-top: 90px;
				padding-right: 60px;
			}
			.terminals-3_left {
				padding-top: 140px;
			}
			.terminals-3_left__header {
				padding-left: 50px;
			}
			.terminals-3_left__description {
				padding-left: 50px;
			}
			.terminals-4_right {
				padding-top: 140px;
			}
			.terminals-4_right__header {
				padding-left: 90px;
			}
			.terminals-4_right__description {
				padding-left: 90px;
			}
			.terminals-5_left {
				padding-top: 170px;
				padding-right: 70px;
			}
			.terminals-5_left__header {
				padding-left: 50px;
			}
			.terminals-5_left__description {
				padding-left: 50px;
			}
			.terminals-3_right::before {
				content: '';
				position: absolute;
				right: 8px;
				top: -55px;
				border: 175px solid transparent;
				border-right: 315px solid $tc2;
				z-index: 1;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	#payment {
		.terminals {
			background: none;
			.terminals-3_right::before {
				border: none;
				display: none;
			}
			.terminals-1_left__description {
				padding-left: 30px;
				padding-top: 0px;
			}
			.terminals-2 {
				padding-top: 30px;
				padding-bottom: 30px;
			}
			.terminals-2_right {
				padding: 0;
				padding-right: 25px;
				padding-top: 90px;
			}
			.terminals-2_right__header {
				padding-left: 30px;
			}
			.terminals-2_right__description {
				padding-left: 30px;
			}
			.terminals-3_left {
				padding-top: 60px;
				padding-right: 10px;
			}
			.terminals-3_left__header {
				padding-left: 30px;
			}
			.terminals-3_left__description {
				padding-left: 30px;
			}
			.terminals-4_right {
				padding-top: 55px;
			}
			.terminals-4_right__header {
				padding-left: 60px;
			}
			.terminals-4_right__description {
				padding-left: 60px;
			}
			.terminals-5_left {
				padding-top: 70px;
				padding-right: 10px;
			}
			.terminals-5_left__header {
				padding-left: 30px;
			}
			.terminals-5_left__description {
				padding-left: 30px;
			}
		}
	}
}
@media (max-width: 767px) {
	#payment {
		.terminals {
			background: none;
			.terminals-3_right::before {
				border: none;
				display: none;
			}
			.terminals-1_left, .terminals-3_left, .terminals-4_right, .terminals-5_left, .terminals-3_right {
				padding: 0;
			}
			.terminals-1_left__description {
				padding-left: 0;
				padding-top: 0;
			}
			.terminals-2 {
				padding-top: 30px;
			}
			.terminals-2_right {
				padding: 30px 0 10px 0;
			}
			.terminals-2_right__header, .terminals-2_right__description, .terminals-3_left__header, .terminals-3_left__description, .terminals-4_right__header, .terminals-4_right__description, .terminals-5_left__header, .terminals-5_left__description {
				padding-left: 0;
			}
			.terminals-3_right {
				padding-top: 10px;
				padding-bottom: 25px;
			}
			.terminals-4_left {
				padding: 10px 30px;
			}
			.terminals-5 {
				padding-top: 20px;
			}
		}
	}
}
/* Yandex money */
#payment {
	.yandex-money_left {
		padding: 0;
		padding-left: 30px;
	}
	.yandex-money_right {
		padding: 0;
	}
	.yandex-money {
		margin: 0;
		padding: 0px;
		border-top: 1px solid $lbc;
		border-bottom: 1px solid $lbc;
		padding-right: 0px;
		padding-bottom: 50px;
		.yandex-money_right__blueBackground {
			padding: 34px 85px 35px 90px;
			background-color: $tc;
			line-height: 1.3em;
			margin-top: 12%;
			span {
				color: $tc2;
			}
		}
		.yandex-money_right__logo {
			text-align: center;
			padding-top: 90px;
		}
		.yandex-money_left__mockup {
			text-align: right;
			padding-top: 30px;
		}
		.yandex-money_right__logo img, .yandex-money_left__mockup img {
			display: inline-block;
			max-width: 100%;
			height: auto;
		}
		.yandex-money_left__lastRow {
			padding-right: 35px;
			padding-top: 20px;
		}
		.yandex-money_left__lastRow__grayText {
			color: #b4b4b4;
		}
		.yandex-money_right__screen {
			padding-right: 10px;
		}
		.yandex-money_right__warning {
			margin-left: 0;
			margin-right: 0;
			display: flex;
			align-items: center;
			padding-left: 12%;
			padding-top: 30px;
		}
		.yandex-money_right__warning__icon {
			border-radius: 50px;
			background-color: $tc;
			padding: 10px 18px;
			color: $tc2;
			font-weight: 600;
			margin-right: 30px;
		}
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	#payment {
		.yandex-money {
			.yandex-money_right__blueBackground {
				padding: 23px 35px 29px 30px;
			}
			.yandex-money_right__warning {
				padding-left: 0;
			}
			.yandex-money_left__lastRow {
				padding-right: 5px;
			}
		}
	}
}
@media (max-width: 991px) {
	#payment {
		.yandex-money_left {
			padding: 0px 30px;
		}
		.yandex-money {
			.yandex-money_right__logo {
				padding-top: 10px;
			}
			.yandex-money_left__mockup {
				text-align: center;
			}
			.yandex-money_right__blueBackground {
				padding: 15px 30px 15px 25px;
				margin-top: 0px;
			}
			.yandex-money_right__warning {
				padding-left: 17%;
				padding-top: 10px;
				padding-right: 10px;
			}
			.yandex-money_left__lastRow {
				padding-top: 0px;
				padding-bottom: 20px;
			}
		}
		.yandex-money .yandex-money_right__warning__icon {
			padding: 10px 18px;
			color: $tc2;
			font-weight: 600;
		}
	}
}
@media (max-width: 767px) {
	#payment {
		.yandex-money {
			.yandex-money_right__warning {
				padding-left: 15px;
			}
			.yandex-money_right__warning__icon {
				margin-right: 0px;
			}
			.yandex-money_right__warning .pull-left p {
				padding-left: 15px;
			}
			.yandex-money_right__screen {
				padding-right: 0px;
			}
		}
		.yandex-money_left, .yandex-money_right {
			padding: 0;
		}
	}
}

/* WebMoney */
#payment {
	.webmoney_left {
		padding: 0;
		padding-left: 30px;
	}
	.webmoney_right {
		padding: 0;
		padding-right: 70px;
		padding-left: 70px;
		padding-top: 50px;
	}
	.webmoney {
		margin: 0;
		padding: 0px;
		border-bottom: 1px solid $lbc;
		padding-right: 0px;
		padding-bottom: 50px;
	}
	.webmoney_left__ul {
		padding-left: 50px;
		margin-top: 110px;
		margin-bottom: 65px;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	#payment {
		.webmoney_left__ul {
			padding-left: 0;
		}
	}
}
@media (max-width: 991px) {
	#payment {
		.webmoney_left {
			padding: 0px 30px;
		}
		.webmoney_left__ul {
			margin-top: 0px;
			margin-bottom: 10px;
		}
		.webmoney_right {
			padding-top: 10px;
		}
	}
}
@media (max-width: 767px) {
	#payment {
		.webmoney_right {
			padding-right: 30px;
			padding-left: 30px;
		}
		.webmoney_left__ul {
			padding-left: 0px;
		}
		.webmoney {
			padding-bottom: 10px;
		}
		.webmoney_left, .webmoney_right {
			padding: 0;
		}
	}
}
/* Credit */
#payment {
	.credit .credit_center {
		padding: 0;
		padding-left: 30px;
		padding-right: 30px;
	}
}
@media (max-width: 767px) {
	#payment {
		.credit .credit_center {
			padding-right: 0;
			padding-left: 0;
			div img {
				width: 100% !important;
			}
		}
	}
}
#payment {
	h2 {
		color: $tc;
	}
}
/* Credit */
#payment {
	.bank-card_right__ul li, .webmoney_left__ul li {
		height: inherit;
		display: flex;
		border-left: 2px dotted $tc;
	}
	.oplata_points {
		height: inherit;
		position: relative;
		right: 12px;
	}
	.oplata_circle_point {
		width: 22px;
		height: 22px;
		border: 1px solid $tc;
		border-radius: 11px;
		color: $tc2;
		background-color: $tc;
		text-align: center;
		font-weight: 600;
		margin-right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.step {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		position: absolute;
		font-size: 18px;
	}
	.bank-card_right__ul li span,
	.webmoney_left__ul li span {
		position: relative;
		height: auto;
	}
	.row {
		.terminals-1 {
			.step {
				top: 40px;
				@media (max-width: $screen-lg-min) {
					top: 10px;
				}
			}
		}
		.terminals-2, .terminals-3, .terminals-5 {
			.step {
				top: -6px;
			}
		}
		.terminals-4 {
			.step {
				top: -10px;
				left: 65px;
				@media (max-width: $screen-lg-min) {
					left: 40px;
				}
			}
		}
	}
}

