/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: $screen-sm-min) {
	.row-offcanvas {
		@include transition(all 0.25s ease-out);
	}
	.row-offcanvas-right {
		right: 0;
		.sidebar-offcanvas {
			right: -70%;
		}
	}
	.row-offcanvas-left {
		.sidebar-offcanvas {
			left: -72%;
			@include transition(all 0.2s ease-out);
		}
		&.active .sidebar-offcanvas {
			left: 0;
		}
	}
	.row-offcanvas-right.active {
		right: 70%;
	}
	.row-offcanvas-left.active {
		#header-layout .header-menu {
			left: 0;
		}
	}
	.sidebar-offcanvas {
		position: absolute;
		top: 60px;
		width: 70%;
		background-color: $tc;
		ul, ol {
			padding: 0;
			li {
				list-style: none;
				padding-left: 10px;
			}
		}
	}
	.bottom-offcanvas {
		border-top: 1px solid $border-color-base;
		padding: 10px 0;
	}
}

/*offcanvas-menu*/
@media (max-width: $screen-xs-max) {
	.offcanvas-inner {
		position: fixed;
		z-index: $zi-mobile-menu;
		background-color: $tc;
		@include size(70%, 100%);
		overflow-y: scroll;
		.offcanvas-body {
			padding: 0 0 130px 0;
		}
	}
	.row-offcanvas {
		position: initial;
		&.active {
			position: relative;
			.disabled-page-area {
				position: fixed;
				width: 100%;
			}
			.active .disabled-page-area {
				position: fixed;
			}
		}
	}
	.disabled-page-area {
		overflow: hidden;
	}
}
@media (max-width: $screen-xs) {
	.offcanvas-inner {
		width: 90%;
	}
	.row-offcanvas-right {
		.sidebar-offcanvas {
			right: -90%;
		}
	}
	.row-offcanvas-left {
		.sidebar-offcanvas {
			left: -92%;
		}
		&.active .sidebar-offcanvas {
			left: 0;
		}
	}
	.row-offcanvas-right.active {
		right: 90%;
	}
}
#offcanvasmenu {
	.nav .caret {
		border-bottom-color: $white;
		border-top-color: $white;
		margin-left: 5px;
		display: none !important;
	}
	.pavo-widget .widget-heading {
		margin-top: 15px;
	}
	li {
		.fa {
			font-size: 22px;
			line-height: 1;
		}
		.fa-minus-square::before {
			content: "\f28e";
		}
		.fa-plus-square::before {
			content: "\f2c2";
		}
	}
	.navbar-nav {
		float: none;
		margin: 0;
		> li {
			float: none;
			background-color: $tc;
			border-right: none;
			&:hover:after {
				display: none;
			}
			.click-canavs-menu {
				cursor: pointer;
				display: inline-block;
				padding: 11px 10px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $wh;
				&:hover {
					color: $tc2;
				}
			}
			li.dropdown-submenu {
				.click-canavs-menu {
					padding: 10px 25px;
				}
				li.dropdown-submenu {
					> .click-canavs-menu {
						padding: 10px 15px;
					}
				}
			}
			> a {
				color: $wh;
				@media (min-width: $screen-sm-min) {
					font-weight: 600;
				}
				font-size: 16px;
				display: block;
				text-transform: uppercase;
				padding-left: 15px;
				&:hover, &:active {
					color: $tc2;
				}
			}
		}
	}
	.dropdown-menu {
		border: 0 none;
		border-radius: 0;
		box-shadow: none;
		display: none;
		float: none;
		margin: 0;
		position: static;
		visibility: visible;
		width: auto !important;
		min-width: 160px;
		@include opacity (1);
		.mega-col {
			padding: 0;
		}
		.row {
			margin: 0;
		}
		a {
			font-size: 13px;
			padding: 5px 0;
			display: block;
			text-transform: uppercase;
			color: $wh;
			&:hover {
				color: $tc2;
			}
		}
	}
	.w-product {
		padding: 0;
	}
	.product-block {
		border: none;
		padding: 0;
		.image {
			float: none;
			margin: 0;
		}
	}
	.cal_all {
		li {
			padding: 5px 20px;
		}
	}
}

.canvas-menu {
	margin: 0;
	background: transparent;
	color: $tc;
	font-size: 28px;
	padding: 0 10px;
	border: none;
}