
/*Text Colors
------------------------------------*/
.text-default {
	color: $brand-default !important;
}

.text-primary {
	color: $brand-primary !important;
}

.text-info {
	color: $brand-info !important;
}

.text-danger {
	color: $brand-danger !important;
}

.text-warning {
	color: $brand-warning !important;
}

.text-success {
	color: $brand-success !important;
}

/*Background Colors
------------------------------------*/
.bg-primary {
	background-color: $brand-primary !important;
}

.bg-info {
	background: $brand-info !important;
}

.bg-danger {
	background: $brand-danger !important;
}

.bg-warning {
	background: $brand-warning !important;
}

.bg-success {
	background: $brand-success !important;
}

.accordion {
	.panel {
		@include box-shadow(0 0px 0px rgba(0, 0, 0, .05));
	}
	.panel-heading {
		padding: 15px;
		position: relative;
		a {
			text-decoration: none;
			font-weight: bold;
			&:before {
				content: "-";
				font-size: 22px;
				font-weight: 600;
				@include size(20px, 20px);
				text-align: center;
				line-height: 16px;
				display: inline-block;
				color: $bo-accordion-color;
			}
			&.collapsed:before {
				content: "+";
				font-size: 18px;
				line-height: 20px;
			}
		}
	}
	.panel-body {
		border-top-color: transparent !important;
	}

	&.collapse-right {
		.panel-heading {
			a:before {
				position: absolute;
				right: 20px;
			}
		}
	}

	&.collapse-color {
		.panel-heading a {
			&:before {
				color: #fff;
				background: $bo-accordion-background;
			}
		}
	}
	&.noborder {
		.panel-heading {
			padding-left: 0;
			padding-right: 0;
		}
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
			border: none;
		}

	}
	&.nobackground {
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
		}
	}
}

.breadcrumbs {
	padding: 15px 0;
	margin-bottom: 0;
	list-style: none;
	background-color: $lg;
	border-radius: $border-radius-base;
	.breadcrumb {
		margin: 0;
		padding: 0 2px;
		li > span {
			color: $tc3;
		}
	}
	h2 {
		margin: 0;
		float: left;
		font-weight: 400;
		position: relative;
	}
}

.breadcrumbs-left {
	h2 {
		float: none;
		text-align: left;
	}
	.breadcrumb-links {
		text-align: left;
	}
}

.heading {
	z-index: 1;
	position: relative;
	text-align: center;
	h1, h2, h3, h4, h5, h6 {
		text-transform: uppercase;
	}
	p, small {
		margin-bottom: 15px;
		display: inline-block;
	}
	&.heading-light {
		color: #eee;
		h1, h2, h3, h4, h5, h6 {
			color: #fff;
		}
		p, small {
			color: #eee;
		}
	}
}

.list-unstyled {
	list-style: none;
	padding-left: 0;
}

@mixin translateY($y) {
	-webkit-transform: translateY($y);
	-ms-transform: translateY($y); // IE9 only
	-o-transform: translateY($y);
	transform: translateY($y);
}

.success {
	display: none;
	width: 600px;
	margin-left: -300px;
	background: white;
	border: 1px solid #E4E3E3;
	padding: 50px;
	left: 50%;
	top: 22%;
	font-size: 16px;
	color: $tc3;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	z-index: 15000;
	position: fixed;
	a {
		text-decoration: none !important;
		font-size: 16px;
		&:hover {
			text-decoration: underline;
		}
	}
	.close {
		opacity: 1;
		float: right;
		position: absolute;
		top: 17px;
		right: 20px;
		@include size(20px,20px);
		cursor: pointer;
		svg {
			@include size(100%, 100%);
			stroke: $tc3;
			stroke-width: 1px;
			&:hover {
				stroke: $tc2;
			}
		}
	}
	.success_button {
		@include transition(0.2s all);
		border: none;
		display: inline-block;
		cursor: pointer;
		font-size: 15px;
		font-weight: 600;
		padding: 6px 24px;
		text-decoration: none;
		text-transform: uppercase;
		&:hover {
			text-decoration: none !important;
		}
		&:active {
			position: relative;
			top: 1px;
		}
	}
}