.col-nopadding{
	.row{
		margin-left: 0;
		margin-right: 0;
		[class*="col-"]{
			padding-left: 0;
			padding-right: 0;
		}
	}
}
.no-padding{
	padding:0;
}
.no-margin{
	margin:0;
}
.nopadding [class*="col-"], .nopadding [class^="col-"]{
	padding-right:0;
	padding-left:0;
}
.fw6 {
	font-weight: 600;
}
.tc {
	color: $tc;
}
.arrow{
	@include size(20px,20px);
	line-height:20px;
	color:#343434;
	font-size:$font-size-base + 5;
	background:$white;
	@include rounded-corners(100%);
}
.address{
	li{
		span,a{
			overflow: hidden;
			display: block;
		}
		.icon{
			@include size(16px,20px);
			background: url(#{$image-theme-path-base}icon-address.png) 0 0 no-repeat;
			margin-right: 10px;
			&.icon-phone{
				background-position: 0 -19px;
			}
			&.icon-mail{
	    		background-position: 0 -43px;
			}
		}
	}	

}
.over-hidden{
	overflow:hidden;
	.col-inner{
		overflow: hidden;
	}
	.owl-carousel .owl-wrapper-outer{
		overflow: visible;
	}
}

/* back top top */
.scrollup {
    background: $theme-color !important;
    bottom: 82px;
    position: fixed;
	right: 10px;
	z-index: 9999;
}
