$wh: #fff !default;
$tc: #222222 !default;
$tc2: #ffd71a !default;
$tc3: #999999 !default;

$lg: #f0f0f0 !default;
$bc: #c4c4c4 !default;/*is this used?*/
$lbc: $lg !default;
$r: #fa3a3a !default;
$g: #59bc6c !default;
$axr: #404040 !default;
$dg: #444444 !default;
$sir: #514fa2 !default;
$deepBlu: #1565c0 !default;


$label-new: $g !default;
$label-sale: $r !default;
$label-present: $sir !default;
$auxiliary: $axr !default;

$f: 'Open Sans', sans-serif !default;
$hf: 'Rubik', sans-serif !default;
$fa: "FontAwesome" !default;

/*z-index*/
$zi-header-menu: 100 !default;
$zi-mobile-menu: 10000 !default;
$zi-ocwall: 9000 !default;

$pagination-active-color: $tc2 !default;