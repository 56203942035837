.owl-carousel {
	display: block !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.filter-right {
	& > div {
		display: flex;
		align-items: center;
	}
	.sort {
		margin-left: 15px;
	}
	label {
		line-height: 14px;
	}
}

.refine-search {
	margin-top: 15px;
	ul, ol {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		padding: 0;
		li {
			margin: 7px 10px;
			line-height: normal;
			position: relative;
			width: calc(25% - 20px);
			a {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				background-size: 90%;
				box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
				@include transition (all 200ms ease-in-out);
				span {
					height: 55px;
					width: 100%;
					padding: 10px 5px;
					text-align: center;
					text-transform: uppercase;
					font-weight: 600;
					color: $tc;
					background: $tc2;
					line-height: 17px;
					display: flex;
					align-items: center;
					justify-content: center;
					&.cat-svg {
						padding: 30px 5px 0;
						@media ( max-width: $screen-xs-max ) {
							padding-top: 20px;
						}
						svg {
							@include size (120px, 120px);
						}
					}
					&.cat-name {
						height: 100px;
						@media ( max-width: $screen-xs-max ) {
							height: 90px;
							padding-top: 0;
						}
					}
					figure {
						text-align: center;
					}
				}
				.cat-img {
					@include size(100%, 170px);
					@media (max-width: $screen-xs-max) {
						display: none;
					}
				}
				&:hover, &:active {
					text-decoration: none;
					span {
						color: $tc2;
						background: $tc;
						&.cat-svg svg path {
							fill: $tc2;
						}
					}
				}
				&:focus {
					text-decoration: none;
				}
			}
			@media (max-width: $screen-xs-max ) {
				width: calc((100% / 3) - 20px);
			}
			@media (max-width: 500px) {
				width: calc((100% / 2) - 20px);
			}
			@media (max-width: 400px) {
				font-size: 12px;
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.col-md-12 {
	.refine-search ul li, .refine-search ol li {
		width: calc(25% - 20px);
		@media (min-width: $screen-lg-min) {
			width: calc(20% - 20px);
		}
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}

}

.product-info {
	.control-label {
		@media (max-width: 360px) {
			width: 100%;
		}
	}
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

/* 
 *  LISTING PRODUCTS PAGE 
 */
.product-filter {
	color: $product-filter-color;
	text-transform: uppercase;
	font-size: $product-filter-font-size;
	font-weight: 500;
	margin: 10px 0;
	span {
		margin-right: 5px;
	}
	.filter-right > div {
		padding-top: 3px;
		padding-left: 5px;
	}

	.display {
		float: left;
	}
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
		text-transform: capitalize;
	}
	.btn-switch {
		display: inline-block;
		margin-right: 5px;
		color: #969696;
		background: transparent;
		padding: 0;
		line-height: 35px;
		font-weight: 500;
		font-size: 34px;
		@include transition (all 200ms ease 0s);
		i {
			padding: 0 5px;
		}
		&:hover, &.active {
			color: $theme-color;
			@include box-shadow (none);
		}
		&#grid-switch {
			font-size: 32px;
		}
	}
}
#form-review-container {
	.set-rating {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		margin-top: 6px;
		margin-bottom: 2px;
		height: 16px;
		width: max-content;
		i {
			margin: 0px;
			display: block;
			float: left;
			padding: 0px 4px 0px 0px;
			font-size: 20px;
			cursor: pointer;
			color: $lg;
			&.selected {
				color: $tc2;
			}
		}
		&:hover i {
			color: $lg;
		}
		& > i:hover, & > i:hover ~ i {
			color: $tc2;
		}
	}
	.recaptcha_container {
		.control-label {
			display: none;
		}
	}
}
.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding-left: 0;
	}
	.product-block {
		display: flex;
		box-shadow: inset 0 0 0 1px $lg;
		margin: 10px 0;
		border-radius: 0;
		.image {
			img {
				@media (min-width: $screen-lg-min) {
					max-width: 285px !important;
				}
			}
		}
		.block-img {
			display: flex;
			width: 30%;
		}
		.product-meta {
			width: 70%;
			padding: 20px 25px 20px 0;
			.top {
				display: flex;
				flex-wrap: wrap;
				.rating {
					display: flex;
					justify-content: flex-start;
					width: fit-content;
					order: 2;
					margin-bottom: 10px;
				}
				.name {
					order: 1;
					font-weight: 600;
					font-size: 18px;
					height: auto !important;
					width: 100%;
					a {
						span {
							display: inline-block;
							width: 100%;
							text-align: left;
							&.title1 {
								margin-bottom: 10px;
							}
							&.title2 {
								margin-bottom: 20px;
							}
						}
					}
				}
				.description {
					order: 3;
					font-family: Open Sans;
					font-style: normal;
					font-weight: normal;
					line-height: normal;
					font-size: 16px;
					color: #999999;
				}
			}
		}
		.bottom {
			padding: 0 20px;
			display: flex;
			width: 100%;
			.price {
				margin-bottom: 15px;
				text-align: left;
				background-color: $tc;
				width: 100%;
				padding-right: 30px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				.price-new {
					font-size: 26px;
					margin-left: 15px;
					color: $tc2;
				}
			}
			.cart {
				button {
					padding: 0 30px;
					height: 45px;
					span {
						margin-left: 15px;
					}
				}
				display: inline-block;
			}
		}
		.compare {
			top: 37px;
			right: 71%;
			button {
				transition-delay: 0s;
				&:hover {
					background-color: $tc2;
				}
			}
		}
		.quickview {
			right: 71%;
			top: 85px;
			a {
				transition-delay: 0s;
				&:hover {
					background-color: $tc;
					svg path {
						fill: $wh;
					}
				}
			}
		}
		&:hover {
			box-shadow: inset 0 0 0 2px $lg;
		}
	}
}

@media (min-width: $screen-lg-min) {
	.product-list {
		.product-block {
			.block-img {
				width: 350px;
				.image {
					padding: 10px 35px;
				}
				.compare, .quickview {
					right: unset;
					left: 295px;
				}
			}
		}
	}
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			margin: 5px;
		}
		.product-block {
			width: calc(100% / 4 - 13px);
		}
	}
	.products-block {
		display: flex;
		flex-wrap: wrap;
		//justify-content: space-around;
	}
	.product-block {
		display: inline-block;
		margin: 10px;
		padding: 20px;
		width: calc(100% / 4 - 20px);
		box-shadow: inset 0 0 0 1px $lg;
		@include rounded-corners(0);
		@include transition(border 0.2s ease-in-out);
		.cart {
			padding: 0;
			flex: 1 1 auto;
			.btn {
				width: 100%;
			}
		}
		.compare {
			top: 150px;
			right: 20px;
			button {
				transition-delay: 0s;
				&:hover {
					background-color: $tc2;
				}
			}
		}
		.quickview {
			top: 198px;
			right: 20px;
			a {
				transition-delay: 0s;
				&:hover {
					background-color: $tc;
					svg path {
						fill: $wh;
					}
				}
			}
		}
		.top {
			.rating {
				height: 0px;
				position: relative;
				top: -15px;
			}
		}
		.bottom {
			@include transition (all 400ms ease 0s);
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50px;
			z-index: 999;
			background-color: $white;
			display: flex;
			.price {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				width: 50%;
				background-color: $tc;
				.price-new {
					color: $tc2;
					font-size: 20px;
					width: 100%;
					text-align: center;
					@media (max-width: $screen-xs-max) {
						font-size: 17px;
					}
				}
				.price-old {
					text-decoration: line-through;
					color: $wh;
					font-size: 15px;
					width: 100%;
					text-align: center;
					@media (max-width: $screen-xs-max) {
						font-size: 13px;
					}
				}
				.price-new:not(:first-child) {
					color: $r;
				}
			}
		}
		&:hover {
			box-shadow: inset 0 0 0 2px $lg;
		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

/* 
* PRODUCT DEALS
*/
.productdeals {
	.widget-inner {
		.carousel {
			position: static;
		}
	}
}

/* 
* PRODUCT INFORMATION PAGE
*/
.product-info {
	.box-product-infomation {
		margin-top: 20px;
	}
	.title-product {
		margin-top: 0;
		font-weight: 600;
	}
	.image-additional {
		overflow: hidden;
		.owl-carousel {
			margin-bottom: 0 !important;
		}
	}
	.image-additional .item {
		border: 1px solid $border-color;
		text-align: center;
		margin: 0 2px;
		background-color: $white;
		position: relative;
		a.active {
			&:before {
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $theme-color;
				content: "";
				left: 0;
				z-index: 1;
				top: -1px;
			}
			&:after {
				position: absolute;
				left: 50%;
				margin-left: -8px;
				border: 6px solid transparent;
				border-bottom: 6px solid $theme-color;
				content: "";
				z-index: 1;
				top: -12px;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.vertical {
		&.image-additional {
			.carousel-control {
				display: none;
			}
		}
		&:hover {
			.carousel-control {
				display: block;
			}
		}

		.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		.top {
			top: 0;
			bottom: auto;
		}

		.bottom {
			top: auto;
			bottom: 0;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;

		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: 1px solid $border-color;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity, .product-meta  {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
	}
	.quantity-adder {
		display: flex;
		position: relative;
		background-color: $white;

		& + .btn-sm {
			padding: 0 18px;
		}
		.quantity-btn-group {
			display: flex;
			flex-wrap: wrap;
			@include size(30px, 40px);
			span {
				&.add-up {
					margin: 0 2px 0 3px;
				}
				&.add-down {
					margin: 4px 0 0 3px;
				}
			}
		}
		.add-action {
			@include size(20px, 18px);
			color: $tc3;
			background-color: $lg;
			display: block;
			position: relative;
			cursor: pointer;
			font-size: 12px;
			line-height: 18px;
			text-align: center;
			&:hover, &:focus {
				color: $wh;
				background-color: $tc;
			}
		}
		.quantity-number {
			input {
				@include size(70px, 40px);
				text-align: center;
				@include inline-block;
				@include rounded-corners(0);
				box-shadow: none;
				padding: 0;
				border: 1px solid $lg;
				color: $tc3;
				background-color: $lg;
				font-size: 16px;
				&:focus {
					background-color: $wh;
				}
			}
		}
		.minimum {
			line-height: 35px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		a {
			a.btn-sm {
				padding: 0 19px;
			}
			&.btn-primary {
				margin-left: 16px;
				font-size: 20px;
				line-height: 33px;
				padding: 0 10px;
			}
		}
		.cart {
			padding-bottom: 0;
			font-size: 0;
		}
		.btn-compare {
			color: $tc;
			font-size: 24px;
			padding: 2px 18px;
			height: 41px;
			&:hover {
				color: $tc;
			}
		}
	}
	.advantages_block {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 10px;
		.advantage_item {
			width: 50%;
			min-width: 255px;
			display: flex;
			align-items: center;
			div {
				@include size(80px, 80px);
				margin: 10px;
			}
			span {
				width: calc(100% - 100px);
				margin: 10px;
				color: $tc3;
			}
		}
		@media (max-width: $screen-xs-max) {
			display: none !important;
		}
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.h1_container {
	padding: 0 15px;
	h1 {
		margin: 0 auto;
		text-transform: uppercase;
		padding: 25px 0 10px 0;
		font-size: 30px;
		display: inline-block;
		width: calc(100% - 40px);
	}
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}

/* contacts page */
.page-information-contact {
	.row {
		margin-bottom: 15px;
	}
	.contact-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			path {
				fill: $tc2;
			}
		}
	}
	.contact-blocks {
		color: $tc3;
		@media (min-width: $screen-md-min) {
			a {
				pointer-events: none;
				cursor: default;
			}
		}
	}
}
.recaptcha_container {
	.control-label:before {
		display: none;
	}
}
/* search page */
.page-product-search {
	.checkbox-block {
		margin-top: 10px;
		.checkbox-inline {
			color: $tc3;
			font-size: 16px;
			line-height: 20px;
		}
	}
}

/* buy */
@media (max-width: $screen-xs-max) {
	.page-checkout-buy{
		table.total-item {
			max-width: unset !important;
		}
	}
}
.page-checkout-buy{
	tbody > td {
		color: $tc3;
		& > span {
			color: $tc3;
		}
	}
	.table.table-bordered.total-item > tbody > tr > td:last-child {
		color: $tc3;
	}
	.advantages {
		& > .form-group {
			display: flex;
			align-items: center;
			margin-top: 5px;
			& > div { padding: 0; }
		}
		img {
			height: unset;
			max-height: 60px;
		}
	}
}
/* site map */
.page-information-sitemap {
	.main-columns {
		li {
			color: $tc3;
			list-style-type: disc;
		}
	}
}
/* compare page */
.page-product-compare {
	.buttons-in-compare {
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 150px;
		margin: 0 auto;
	}
	tbody > tr {
		color: $tc3;
	}
}
/* information page */
.page-information {
	li {
		color: $tc3;
	}
	strong {
		font-weight: 600;
	}
}
/* home page */
.page-home {
	.defhome {
		padding: 0 0 30px 0;
		display: flex;
		flex-wrap: wrap;
		& > .pav-container {
			width: 100%;
			&:first-child {
				order: 3;
				@media (max-width: 475px) {
					& .row-inner > div {
						padding: 0;
						.all-cat-special {
							.special-cat {
								width: calc(100% / 2 - 8px);
								margin: 4px;
							}
						}
					}
				}
			}
			&:nth-child(2) { order: 1; }
			&:nth-child(3) { order: 2; }
			&:nth-child(4) { order: 4; }
		}
		& .all-cat-special {
			display: flex;
			flex-wrap: wrap;
			.special-cat {
				@media (min-width: $screen-lg-min) {
					width: calc(100% / 6 - 4px);
				}
				@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
					width: calc(100% / 3 - 4px);
				}
				@media (min-width: 476px) and (max-width: $screen-xs-max) {
					width: calc(100% / 2 - 8px);
					margin: 4px;
				}
				margin: 2px;
				padding: 10px;
				@include colors($tc2, $tc);
				&:first-child {
					order: 1;
				}
				&:nth-child(2) {
					order: 3;
				}
				&:nth-child(3) {
					order: 4;
				}
				&:nth-child(4) {
					order: 5;
				}
				&:nth-child(5) {
					order: 6;
				}
				&:last-child {
					order: 2;
				}
				a {
					display: flex;
					flex-wrap: wrap;
					.cat-svg {
						width: 100%;
						display: flex;
						justify-content: center;
						@media (max-width: 475px) {
							padding: 5px 0 0 0;
						}
						padding: 20px 0 5px 0;
						svg {
							@include size(120px, 120px);
							@media (max-width: $screen-xs-max) {
								@include size(90px, 90px);
							}
							fill: $tc2;
						}
					}
					.title {
						@media (min-width: $screen-lg-min) {
							font-size: 14px;
						}
						font-size: 16px;
						@media (max-width: 475px) {
							font-size: 11px;
						}
						text-transform: uppercase;
						font-family: $f;
						color: $tc2;
						font-weight: 600;
						@include size(100%, 75px);
						@media (max-width: 475px) {
							padding: 0 0 5px 0;
							height: 35px;
						}
						padding: 5px 0 20px 0;
						display: flex;
						justify-content: center;
						align-items: center;
						text-align: center;
					}
				}
				&:hover {
					cursor: pointer;
					background-color: $tc2;
					.cat-svg {
						svg {
							fill: $tc;
						}
					}
					.title {
						color: $tc;
					}
				}
			}
		}
	}
}
/* success page */
.page-checkout-success {
	#content .well > h1 {
		padding-left: 0;
		&:after {
			background-color: $tc2;
			bottom: 5px;
			left: 0;
		}
	}
}
/* category description */
.bottom_category_desc {
	ul {
		font-size: 16px;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		line-height: normal;
		color: $tc3;
	}
	h2, h3 {
		font-size: 24px;
	}
}